import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// partial
// import LottieObserver from "./partial/lottie-observer"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

// json
// import animationGantiPlan from "assets/json/myxl/ganti-plan.json"

// assets
import imgPlanBooster from "assets/img/common/myxl/img-plan-booster.png"
import grfxOShape from "assets/img/common/myxl/grfx-o-shape-stroke.png"

const MyXLPlanBooster = () => {
  const [trigger, anim] = useScrollAnim(0.65)

  return (
    <section ref={trigger} className="sc-myxl-plan-booster pb-main">
      <div className="container">
        <div className="row">
          <div className="col-md-6 myxl-plan-booster-description mb-md-down-3">
            <h2 className={`${anim(1)}`}>Ganti Plan atau Beli Booster</h2>
            <p className={`lead ${anim(2)}`}>
              Butuh pemakaian yang lebih besar? Ubah plan Anda atau beli Booster
              dengan mudah lewat aplikasi myXL
            </p>
          </div>
          <div className={`col-md-6 myxl-plan-booster-img ${anim(1)}`}>
            <div className={`img-plan-booster`}>
              <img
                src={imgPlanBooster}
                alt="Plan Booster"
                className={`img-fluid ${anim(2)}`}
              />
              <div className={`white-gradient ${anim(2)}`}></div>
            </div>
            <div className="plan-booster-lottie">
              <img
                src={grfxOShape}
                width={400}
                alt="O Shape Stroke"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyXLPlanBooster
