import React from "react"

// anti components
import { Link } from "components/anti/link/link"

// assets
import btnPlayStore from "assets/img/common/button_playstore-alt.png"
import btnAppStore from "assets/img/common/button_appstore-alt.png"

// mixins
import { onClickDataLayer } from "src/mixins/mixins"

const MyXLDownload = () => {
  const handleClick = (text) => {
    onClickDataLayer("appsDownload", text, "/myxl")
  }

  return (
    <section className="sc-myxl-download py-main">
      <div className="bg-myxl-download"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-8 mb-lg-down-4">
            <h2>
              Fitur Powerful dan Tampilan Terupdate, Download myXL Sekarang
            </h2>
          </div>
          <div className="col-xl-4 myxl-download-button">
            <Link
              onClick={() => handleClick("App Store")}
              to="https://apps.apple.com/us/app/myxl-cek-kuota-beli-paket-xl/id683141076"
            >
              <img src={btnAppStore} alt="ic-appstore" className="img-fluid" />
            </Link>
            <Link
              onClick={() => handleClick("Play Store")}
              to="https://play.google.com/store/apps/details?id=com.apps.MyXL"
            >
              <img
                src={btnPlayStore}
                alt="ic-playstore"
                className="img-fluid"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyXLDownload
