import React from "react"

import { Cover } from "components/anti/cover/cover"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

const MyXLCover = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section ref={trigger} className="sc-myxl-cover">
      <Cover
        title={data?.title}
        text={data?.description}
        imgMd={data?.imageDesktop?.mediaItemUrl}
        img={data?.imageMobile?.mediaItemUrl}
        // imgMd={data?.imageDesktop?.mediaItemUrlSharp?.childImageSharp?.fluid}
        // img={data?.imageMobile?.mediaItemUrlSharp?.childImageSharp?.fluid}
        btnDownload={data?.buttons}
        imgHeight="r-4-5 h-450px h-md-500px"
        titleClassName={`${anim(1)}`}
        textClassName={`${anim(2)}`}
        btnDownloadClassName={`${anim(3)}`}
        className="cover-myxl"
      />
    </section>
  )
}

export default MyXLCover
