import React from "react"
import Lottie from "react-lottie"
import { useScrollAnim } from "components/hooks/hooks"

const LottieObserver = ({ json, width, height, loop, autoplay, className }) => {
  const [trigger, anim, inView] = useScrollAnim()

  return (
    <div ref={trigger}>
      <div className={`${className} ${anim(2)}`}>
        {inView && (
          <Lottie
            options={{
              loop,
              autoplay,
              animationData: json,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid meet",
              },
            }}
            width={width}
            height={height}
          />
        )}
      </div>
    </div>
  )
}

LottieObserver.defaultProps = {
  width: "100%",
  height: "100%",
  loop: false,
  autoplay: true,
}

export default LottieObserver
