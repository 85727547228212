import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

// assets
import grfxOShape from "assets/img/common/myxl/grfx-o-shape-stroke.png"
import balanceCheckPhone from "assets/img/common/myxl/balance-check-phone_old.png"
import balanceCheckCard from "assets/img/common/myxl/balance-check-card.png"
import surpriseEgg from "assets/img/common/myxl/surprise-egg.png"

const MyXLBalanceCheck = () => {
  const [trigger, anim] = useScrollAnim(0.5)

  return (
    <section ref={trigger} className="sc-myxl-balance-check pb-main">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 order-last order-md-first myxl-balance-check-image">
            <div className={`myxl-balance-check-image-wrapper ${anim(1)}`}>
              <div className={`animate-o-shape`}>
                <img
                  src={grfxOShape}
                  alt="animate-o-shape"
                  className="img-fluid"
                />
              </div>
              <div className="image-phone">
                <img
                  src={balanceCheckPhone}
                  alt="img-phone"
                  width={280}
                  className={`img-fluid ${anim(2)}`}
                />
              </div>
              <div className="image-card">
                <img
                  src={balanceCheckCard}
                  alt="img-phone"
                  className={`img-fluid ${anim(2)}`}
                />
              </div>
              <div className="image-surprise-egg">
                <img
                  src={surpriseEgg}
                  alt="surprise-egg-img"
                  className={`img-fluid ${anim(7, "fadeInScaleUp")}`}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 order-first order-md-last myxl-balance-check-description">
            <h2 className={`${anim(1)}`}>
              Cek Pemakaian, Saldo, dan Main Surprise Egg!
            </h2>
            <p className={`lead ${anim(2)}`}>
              Sisa pemakaian & saldo Anda akan terlihat di halaman pertama,
              begitu juga fitur Surprise Egg untuk promo tertentu.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyXLBalanceCheck
