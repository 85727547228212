import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// partial
import LottieObserver from "./partial/lottie-observer"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

// assets
import animationChartPromo from "assets/json/myxl/chart-promo-ekslusif.json"
import imgPromo from "assets/img/common/myxl/img-promo.png"

const MyXLPromo = () => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section ref={trigger} className="sc-myxl-promo py-main">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 myxl-promo-description">
            <h2 className={`${anim(1)}`}>Promo Eksklusif Untuk Pelanggan</h2>
            <p className={`lead ${anim(2)}`}>
              Temukan beragam promo menarik khusus untuk pelanggan pascabayar XL
              PRIORITAS hanya Di Aplikasi myXL
            </p>
          </div>
          <div className="col-md-6 myxl-promo-graphic">
            <div className="myxl-promo-img">
              {/* <StaticImage
                src="../../../assets/img/common/myxl/img-promo.png"
                alt="img-promo"
                className={`${anim(1)}`}
              /> */}
              <img
                src={imgPromo}
                alt="img-promo"
                className={`img-fluid ${anim(1)}`}
              />
            </div>
            <div className="myxl-promo-lottie">
              <LottieObserver json={animationChartPromo} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyXLPromo
