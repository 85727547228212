import React from "react"

// partial
import LottieObserver from "./partial/lottie-observer"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

// json
import animationSparkleMultiAccount from "assets/json/myxl/blink-multi-account.json"

// temp assets
import imgPhoto1 from "assets/img/photos/photo-1.png"
import imgPhoto2 from "assets/img/photos/photo-2.png"
import imgPhoto3 from "assets/img/photos/photo-3.png"
import imgPhoto4 from "assets/img/photos/photo-4.png"
import imgPhoto5 from "assets/img/photos/photo-5.png"
// import grfxSparkle1 from "assets/img/common/grfx-sparkle-1.png"
// import grfxSparkle2 from "assets/img/common/grfx-sparkle-2.png"
// import grfxSparkle3 from "assets/img/common/grfx-sparkle-3.png"

const MyXLMultiAccount = () => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section ref={trigger} className="sc-myxl-multiaccount pb-main">
      <div className="container">
        <div className="row align-items-center">
          <div className={`col-md-6 myxl-multiaccount-photos ${anim(1)}`}>
            <div className="myxl-multiaccount-photos-sparkle">
              <LottieObserver json={animationSparkleMultiAccount} loop />
            </div>
            <div className="myxl-multiaccount-photos-wrapper">
              <div className="myxl-multiaccount-photos__item-1">
                {/* <div className="sparkle">
                  <img
                    src={grfxSparkle2}
                    alt="sparkle-2"
                    className="img-fluid"
                  />
                </div> */}
                <img
                  src={imgPhoto1}
                  alt="photos-1"
                  className="img-fluid"
                  width="104px"
                />
              </div>
              <div className="myxl-multiaccount-photos__item-2">
                {/* <div className="sparkle">
                  <img
                    src={grfxSparkle1}
                    alt="sparkle-1"
                    className="img-fluid"
                  />
                </div> */}
                <img
                  src={imgPhoto2}
                  alt="photos-2"
                  className="img-fluid"
                  width="202px"
                />
              </div>
              <div className="myxl-multiaccount-photos__item-3">
                {/* <div className="sparkle">
                  <img
                    src={grfxSparkle3}
                    alt="sparkle-3"
                    className="img-fluid"
                  />
                </div> */}
                <img
                  src={imgPhoto3}
                  alt="photos-3"
                  className="img-fluid"
                  width="152px"
                />
              </div>
              <div className="myxl-multiaccount-photos__item-4">
                <img
                  src={imgPhoto4}
                  alt="photos-4"
                  className="img-fluid"
                  width="129px"
                />
              </div>
              <div className="myxl-multiaccount-photos__item-5">
                <img
                  src={imgPhoto5}
                  alt="photos-5"
                  className="img-fluid"
                  width="92px"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 offset-md-1 order-first order-md-last myxl-multiaccount-description">
            <h2 className={`${anim(1)}`}>Multi-Account</h2>
            <p className={`lead ${anim(2)}`}>
              Kontrol akun berbagai nomor XL hanya dalam satu aplikasi myXL
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyXLMultiAccount
