import React from "react"

// anti components
import { Button } from "components/anti/buttons/buttons"

// partial
import LottieObserver from "./partial/lottie-observer"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

// json
import animationLiveChat from "assets/json/myxl/layanan-via-chat.json"

// mixins
import { onClickDataLayer } from "src/mixins/mixins"

const MyXLLiveChat = () => {
  const [trigger, anim] = useScrollAnim()

  const handleClick = () => {
    onClickDataLayer("chatUsButton", "Chat Us", "/myxl")
  }

  return (
    <section ref={trigger} className="sc-myxl-livechat py-main">
      <div className="bg-livechat"></div>
      <div className="container">
        <div className="row">
          <div
            className={`col-md-6 col-md-6 order-last order-md-first myxl-livechat-sample ${anim(
              1
            )}`}
          >
            <div className="myxl-livechat-sample-wrapper">
              {/* <div className="myxl-livechat-sample__item">
                <span>Selamat datang di XL Prioritas</span>
              </div>
              <div className="myxl-livechat-sample__item mb-4">
                <span>
                  Klik 'Start Chatting' untuk terhubung ke <br /> live chat kami
                </span>
              </div> */}
              <LottieObserver json={animationLiveChat} />
              <Button
                link="https://wa.me/62818800055"
                variant="secondary"
                onClick={() => handleClick()}
              >
                CHAT SEKARANG
              </Button>
            </div>
          </div>
          <div className="col-md-6 col-md-6 order-first order-md-last myxl-livechat-description mb-md-down-3">
            <h2 className={`${anim(1)}`}>Layanan Live Chat</h2>
            <p className={`lead ${anim(2)}`}>
              Tim Live Chat kami siap untuk menjawab pertanyaan dan memberikan
              solusi terbaik untuk Anda
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyXLLiveChat
