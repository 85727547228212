import React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

// utils
import { currencyFormatter } from "src/mixins/mixins"

// partial
import LottieObserver from "./partial/lottie-observer"

// hooks
import { useScrollAnim, useNumberControls } from "components/hooks/hooks"

// json
import animationBatasPemakaian from "assets/json/myxl/batas-pemakaian.json"

const MyXLUsageLimit = () => {
  const [number, increment, decrement] = useNumberControls(
    200000,
    50000,
    300000,
    50000
  )
  const [trigger, anim] = useScrollAnim(0.5)

  return (
    <section ref={trigger} className="sc-myxl-usage-limit pb-main">
      <div className="container">
        <div className="row">
          <div className="col-md-6 myxl-usage-limit-description mb-3 mb-md-0">
            <h2 className={`${anim(1)}`}>Atur Batas Pemakaian di myXL</h2>
            <p className={`lead ${anim(2)}`}>
              Anti bill-shock dengan mengatur batas pemakaian sesuai keinginan
              Makin Mudah dengan Aplikasi myXL
            </p>
          </div>
          <div className="col-md-6 myxl-usage-limit-controls">
            <div className="box box-shadow myxl-usage-limit-controls-wrapper">
              <div className="box-content">
                <h5>Jadikan Batas Pemakaian Saya</h5>
                <div className="myxl-usage-limit-controls-actions">
                  <motion.button
                    whileTap={{ scale: 1.2 }}
                    whileHover={{ scale: 1.1 }}
                    onClick={decrement}
                  >
                    -
                  </motion.button>
                  <span className={`limit-number h2`}>
                    <span>{currencyFormatter("ID", number)}</span>
                  </span>
                  <motion.button
                    whileTap={{ scale: 1.2 }}
                    whileHover={{ scale: 1.1 }}
                    onClick={increment}
                  >
                    +
                  </motion.button>
                </div>
              </div>
              <div className="box-footer">
                <p className="p-0 m-0">
                  Cukup satu kali atur, Batas Pemakaian Anda berlaku untuk bulan
                  ini dan seterusnya.
                </p>
                {/* <StaticImage
                  src="../../../assets/img/icon/ic-thumb.png"
                  alt="Thumb"
                  className="ic-thumb"
                  width={100}
                /> */}
                <LottieObserver
                  json={animationBatasPemakaian}
                  className="ic-thumb"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyXLUsageLimit
