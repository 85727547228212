import React, { memo } from "react"
import { graphql, useStaticQuery } from "gatsby"

// anti components
import Layout from "components/Layout"
import Seo from "components/SEO"

// myxl components
import MyXLCover from "components/pages/myxl/myxl-cover"
import MyXLOverview from "components/pages/myxl/myxl-overview"
import MyXLBalanceCheck from "components/pages/myxl/myxl-balance-check"
import MyXLUsageLimit from "components/pages/myxl/myxl-usage-limit"
import MyXLPrioFlex from "components/pages/myxl/myxl-prio-flex"
import MyXLPlanBooster from "components/pages/myxl/myxl-plan-booster"
import MyXLLiveChat from "components/pages/myxl/myxl-livechat"
import MyXLPromo from "components/pages/myxl/myxl-promo"
import MyXLMultiAccount from "components/pages/myxl/myxl-multiaccount"
import MyXLDownload from "components/pages/myxl/myxl-download"

const MyXLPage = ({ location }) => {
  const page = useStaticQuery(Q_MYXL).wordPress.page
  const { myxl: myXL, seo: SEO, general } = page

  const { cover, lookingForSomething } = myXL
  return (
    <>
      <Seo YoastSEO={SEO} location={location} />
      <Layout data={general}>
        <MyXLCover data={cover} />
        <MyXLOverview data={lookingForSomething} />
        <MyXLBalanceCheck />
        <MyXLUsageLimit />
        <MyXLPrioFlex />
        <MyXLPlanBooster />
        <MyXLLiveChat />
        <MyXLPromo />
        <MyXLMultiAccount />
        <MyXLDownload />
      </Layout>
    </>
  )
}

const Q_MYXL = graphql`
  query QueryMyXL {
    wordPress {
      page(id: "myxl", idType: URI) {
        general {
          footer {
            description
          }
        }
        myxl {
          cover {
            imageDesktop {
              mediaItemUrl
              #mediaItemUrlSharp {
              #  childImageSharp {
              #    gatsbyImageData(layout: FULL_WIDTH)
              #    fluid {
              #      base64
              #      src
              #      srcSet
              #      sizes
              #      aspectRatio
              #    }
              #  }
              #}
            }
            imageMobile {
              mediaItemUrl
              #mediaItemUrlSharp {
              #  childImageSharp {
              #    gatsbyImageData(layout: FULL_WIDTH)
              #    fluid {
              #      base64
              #      src
              #      srcSet
              #      sizes
              #      aspectRatio
              #    }
              #  }
              #}
            }
            title
            description
            url
            buttons {
              icon {
                mediaItemUrl
                #mediaItemUrlSharp {
                #  childImageSharp {
                #    gatsbyImageData(layout: FULL_WIDTH)
                #  }
                #}
              }
              url
              label
            }
          }
          lookingForSomething {
            text
            layoutType
            iconList {
              icon {
                mediaItemUrl
                #mediaItemUrlSharp {
                #  childImageSharp {
                #    gatsbyImageData(layout: FULL_WIDTH)
                #  }
                #}
              }
              text
            }
            isCustom
            image {
              mediaItemUrl
              #mediaItemUrlSharp {
              #  childImageSharp {
              #    gatsbyImageData(layout: FULL_WIDTH)
              #  }
              #}
            }
          }
        }
        seo {
          canonical
          title
          metaDesc
          focuskw
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphAuthor
          opengraphDescription
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          opengraphUrl
          opengraphSiteName
          opengraphPublishedTime
          opengraphModifiedTime
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          breadcrumbs {
            url
            text
          }
          cornerstone
          readingTime
        }
      }
    }
  }
`

export default memo(MyXLPage)
