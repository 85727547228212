import React, { useRef, useState, useCallback } from "react"
import { motion, AnimatePresence } from "framer-motion"

// anti components
import { Slider } from "components/anti/slider/slider"
import { Link } from "components/anti/link/link"

// variants framer motion
import { basicFadeInOut } from "components/global/variants"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

// partial
import LottieSlideObserver from "./partial/lottie-slide-observer"

const SlideListing = ({ item }) => {
  const [listingTrigger, listingAnim] = useScrollAnim()

  return (
    <>
      <div ref={listingTrigger} className="myxl-overview-slide-listing">
        {item?.iconList.map((item, j) => (
          <Link
            // to={item.url}
            // to={`https://google.com`}
            key={j}
            className={`myxl-overview-slide-listing__item ${listingAnim(
              j + 1
            )}`}
          >
            <img
              src={item?.icon?.mediaItemUrl}
              alt={`overview-slide-listing-item-${j}`}
              className={`img-fluid`}
            />
            <span>{item?.text}</span>
          </Link>
        ))}
      </div>
    </>
  )
}

const OverviewTitlePresence = ({ data, currentSlider }) => {
  return (
    <AnimatePresence initial={false}>
      {data?.map((item, i) => {
        return (
          currentSlider === i && (
            <motion.h2
              variants={basicFadeInOut}
              initial="initial"
              animate="animate"
              exit="exit"
              key={`key-${i}`}
            >
              {item?.text}
            </motion.h2>
          )
        )
      })}
    </AnimatePresence>
  )
}

const MyXLOverview = ({ data }) => {
  const sliderRef = useRef()
  const [currentSlider, setCurrentSlider] = useState(0)
  const [sliderIndexAfterChange, setSliderIndexAfterChange] = useState(0)

  const handleDotsClick = useCallback(
    (index) => {
      sliderRef.current.slickGoTo(index)
    },
    [sliderRef]
  )

  return (
    <section className="sc-myxl-overview py-main">
      <div className="container">
        <div className="row">
          <div className={`myxl-overview-content col-md-6 fadeInUp`}>
            <div className="dots-slider">
              <div
                className="dots-slider__active-absolute"
                style={{
                  width: `${(1 / data?.length ?? 0) * 100}%`,
                  left: `${(currentSlider / data?.length ?? 0) * 100}%`,
                }}
              ></div>
              {data?.map((item, i) => (
                <span
                  key={i}
                  onClick={() => handleDotsClick(i)}
                  className={`dots-slider__item`}
                  style={{ width: `${(1 / data?.length ?? 0) * 100}%` }}
                  role="button"
                  aria-hidden="true"
                  tabIndex={0}
                ></span>
              ))}
            </div>
            <div className="myxl-overview-content-title">
              <OverviewTitlePresence
                currentSlider={currentSlider}
                data={data}
              />
            </div>
          </div>
          <div
            className={`myxl-overview-slide overflow-hidden col-md-6 fadeInUp delayp1`}
          >
            <Slider
              forwardRef={sliderRef}
              // infinite
              // autoplay
              // speed={500}
              // autoplaySpeed={5000}
              showInitial={1}
              showXxlDown={1}
              showXlDown={1}
              showLgDown={1}
              showMdDown={1}
              showSmDown={1}
              arrowsInitial={false}
              arrowsXxlDown={false}
              arrowsXlDown={false}
              arrowsLgDown={false}
              arrowsMdDown={false}
              arrowsSmDown={false}
              beforeChange={(current, next) => {
                setCurrentSlider(next)
              }}
              afterChange={(current) => {
                setSliderIndexAfterChange(current)
              }}
            >
              {data?.map((item, i) => (
                <div
                  key={i}
                  className={`myxl-overview-slider-wrapper ${
                    sliderIndexAfterChange === i ? `fadeIn` : ``
                  }`}
                >
                  {item?.layoutType === "image" ? (
                    <div>
                      {!!item?.isCustom ? (
                        <img
                          src={item?.image?.mediaItemUrl}
                          alt={`overview-img-${i}`}
                          className={`img-fluid mx-auto`}
                        />
                      ) : (
                        <>
                          {sliderIndexAfterChange === i && (
                            <LottieSlideObserver index={i} />
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="flex-center">
                      {sliderIndexAfterChange === i && (
                        <SlideListing item={item} />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyXLOverview
