import React from "react"
import Lottie from "react-lottie"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

// assets
import grfxOShape from "assets/img/common/myxl/grfx-o-shape.png"
import grfxPhone from "assets/img/common/myxl/phone-1.png"

// json
import animationLoadingLebihCepat from "assets/json/myxl/loading-lebih-cepat.json"

const LottieSlideObserver = ({ index }) => {
  const [trigger, anim, inView] = useScrollAnim()
  return (
    <div ref={trigger}>
      {index === 0 ? (
        <div className="myxl-overview-slide-observer slide-observer-1">
          {inView && (
            <>
              <img
                src={grfxOShape}
                alt="grfx-o-shape"
                className={`o-shape img-fluid ${anim(1)}`}
              />
              <img
                src={grfxPhone}
                alt="grfx-phone"
                className={`grfx-phone img-fluid ${anim(4)}`}
              />
            </>
          )}
        </div>
      ) : (
        <div className="myxl-overview-slide-observer slide-observer-3">
          {inView && (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: animationLoadingLebihCepat,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid meet",
                },
              }}
              className="slide-observer-lottie"
            />
          )}
        </div>
      )}
    </div>
  )
}

export default LottieSlideObserver
