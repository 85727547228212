import React from "react"
// import { StaticImage } from "gatsby-plugin-image"

// partial
import LottieObserver from "./partial/lottie-observer"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

// json
import animationPrioFlex from "assets/json/myxl/prio-flex.json"

const MyXLPrioFlex = () => {
  const [trigger, anim] = useScrollAnim(0.65)

  return (
    <section ref={trigger} className="sc-myxl-prio-flex pb-main">
      <div className="container">
        <div className="row align-items-center">
          <div className={`col-md-6 myxl-prio-flex-img ${anim(1)}`}>
            <LottieObserver json={animationPrioFlex} width={400} />
          </div>
          <div className="col-md-6 order-first order-md-last myxl-prio-flex-description">
            <h2 className={`${anim(1)}`}>Isi Pulsa PRIO Flex</h2>
            <p className={`lead ${anim(2)}`}>
              Gunakan pulsa PRIO Flex untuk membeli Booster yang Anda butuhkan
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyXLPrioFlex
